/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// Next step: use primitive methods in Date and Intl native class.
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl (even IE 11 suports it)
import { addDays, addWeeks, differenceInMilliseconds, format, formatDuration, formatISO, intervalToDuration, isAfter, isBefore, isEqual, isValid, parse, parseISO } from "date-fns";

// This class depends on custom_typings/extensions/
Date.prototype.isBefore = function (b: Date) {
    const self: Date = this;

    return isBefore(self, b);
};

Date.prototype.isAfter = function (b: Date) {
    const self: Date = this;

    return isAfter(self, b);
};

Date.prototype.add = function (amount: number, type: "week") {
    const self: Date = this;

    if (type === "week")
        return addWeeks(self, amount);
    else if (type === "days")
        return addDays(self, amount);
    else
        throw new Error("Type not supported");
};


Date.prototype.subtract = function (amount: number, type: "week") {
    const self: Date = this;

    if (type === "week")
        return addWeeks(self, amount * -1);
    else if (type === "days")
        return addDays(self, amount * -1);
    else
        throw new Error("Type not supported");
};

Date.prototype.isSameOrAfter = function (b: Date) {
    const self: Date = this;

    return isEqual(self, b) || isAfter(self, b);
};

Date.prototype.isSameOrBefore = function (b: Date) {
    const self: Date = this;

    return isEqual(self, b) || isBefore(self, b);

};

Date.prototype.fromNow = function () {
    const self: Date = this;

    const duration = intervalToDuration({
        start: self,
        end: new Date()
    });

    return formatDuration(duration);
};

Date.prototype.format = function (formatTokens: string) {
    const self: Date = this;

    return format(self, formatTokens);

};

Date.prototype.formatISO = function () {
    const self: Date = this;

    return formatISO(self);

};

Date.prototype.local = function () {
    const self: Date = this;

    return self;
};
Date.prototype.diff = function (b: Date) {
    const self: Date = this;

    return differenceInMilliseconds(self, b);

};
Date.prototype.isValid = function () {
    const self: Date = this;

    return isValid(self);
};

Date.utc = function (input: Date) {

    return Date.from(input);
}
Date.from = function (input: string | Date, format: string = null) {
    if (input && input instanceof Date)
        return input;
    else if (input === undefined || input === null)
        return undefined;
    else if (format)
        return input && input !== "" ? parse(input?.toString(), format, new Date(), {}) : null;
    else
        return input && input !== "" ? parseISO(input?.toString()) : null;

}
Date.humanizeDuration = function (diff: number) {

    const duration = intervalToDuration({ start: 0, end: diff })
    return formatDuration(duration);
}
