import React from 'react';
import { useEffect } from 'react';
import { useSiteHeaderSelector } from '@src/customHooks/siteHeaderStore';
import { getAppInsights } from '@src/TelemetryProvider';
import jwt from '@src/utils/jwt';

interface GainsightProviderProps {
    children: React.ReactNode;
}

const GainsightProvider: React.FC<GainsightProviderProps> = ({ children }) => {
    const hasValidToken = jwt.hasValidToken();
    const userIsLoaded = useSiteHeaderSelector((x) => !!x.user);
    const activeProfile = useSiteHeaderSelector((x) => x.profiles)?.find(x => x.isSelected);
    const organization = useSiteHeaderSelector((x) => x.organization);
    const organizationName = useSiteHeaderSelector((x) => x.organizationName);

    useEffect(() => {
        if (hasValidToken && userIsLoaded && activeProfile && organization && organizationName) {
            const initializeGainsight = async () => {
                try {
                    const gdprCountryCodes = new Set([
                        'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR',
                        'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL',
                        'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'CH', 'GB', 'IS',
                        'NO', 'LI'
                    ]);

                    const isProduction = process.env.GAINSIGHT_TAG?.split('-').length === 3; // Prod tag has 3 parts, preprod has 4.

                    let profileId = activeProfile.id;
                    let userIdTag = `PC${isProduction ? '' : '-PREPROD'}`; // Add PREPROD if not in production

                    if (gdprCountryCodes.has(organization.countryCode.toUpperCase().trim())) {
                        profileId = Math.floor(Math.random() * (1000000 - 1) + 1).toString();
                        // Set GDPR tag for user in GDPR countries
                        userIdTag += `-GDPR`;
                    }

                    // Hash the profile ID
                    const hashedIdBuffer = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(profileId));
                    const hashArray = Array.from(new Uint8Array(hashedIdBuffer));
                    const hashedId = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

                    userIdTag += `-${hashedId}`;

                    // Initialize Gainsight
                    //@ts-expect-error - aptrinsic is Gainsight.
                    aptrinsic("identify",
                        {
                            //User Fields
                            "id": userIdTag, // Either regular hashed id or GDPR tag
                            "role": activeProfile.userRole, // The role of the user's profile.
                        },
                        {
                            //Account Fields
                            "id": `PC-${organization.id}`, // This should be the org id.
                            "name": organizationName, // Name of the org that the profile belongs to.
                        }
                    );
                    console.log('Gainsight initialized');
                } catch (error) {
                    console.error(error);
                    const appInsights = getAppInsights();
                    appInsights.trackException({ exception: error as Error });
                }
            };

            initializeGainsight();
        }
    }, [hasValidToken, userIsLoaded, activeProfile, organization, organizationName]);

    return <>{children}</>;
};

export default GainsightProvider;
