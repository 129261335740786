import * as React from "react";
import { FC } from "react";
import { connect } from "react-redux";
import { siteHeaderContext } from "../SiteHeader/Domain/Store";
import { State as AppState } from "./Domain/Store";

interface Props {
    isPermissionClick: boolean;
    headwayApiKey: string;
    isAdmin: boolean;
    subscriptionUri: string;
    logoUri: string;
}

const PrivateFooterComponent: FC<Props> = () => {

    return <footer className="page">
        <div className="layout-content">
            <a
                id="Faq"
                href="https://ps.powerschool-docs.com/permissionclick/latest/"
                rel="noreferrer noopener"
                target="_blank"
                className="btn"
            >
                Tips &amp; FAQ
            </a>
        </div>
    </footer>;
};

export const PrivateFooter = connect(
    (state: AppState): Partial<Props> => {

        return {
            isPermissionClick: state.isPermissionClick,
            headwayApiKey: state.headwayApiKey,
            isAdmin: state.isAdmin,
            subscriptionUri: state.subscriptionUri,
            logoUri: state.logoUri,
        };
    }, undefined, undefined, { context: siteHeaderContext }
)(PrivateFooterComponent);
