import { createContext } from "react";
import { ReactReduxContextValue } from "react-redux";
import { Action } from "redux";
import { OrganizationListItem } from "../../../../sharedDomain/OrganizationListItem";
import { createStore } from "../../../StoreFactory";
import { BrandingTextValue } from "../../CustomizeBranding/Domain/BrandingTextValue";
import { AvailableCountryListItem } from "./AvailableCountryListItem";
import { ProfileListItem } from "./ProfileListItem";
import SetAcceptedTerms from "./Reducer/SetAcceptedTerms";
import SetError from "./Reducer/SetError";
import SetFirstLoad from "./Reducer/SetFirstLoad";
import SetOrganization from "./Reducer/SetOrganization";
import SetOrganizations from "./Reducer/SetOrganizations";
import SetUnseenReportRequestCount from "./Reducer/SetUnseenReportRequestCount";
import { ReportsNavState } from "./ReportsNavState";

export interface SiteHeaderState extends ReportsNavState {
    shardCode: string;

    brandingTextValues: BrandingTextValue[]
    isReadonly: boolean;
    isSuperOrg: boolean;
    isDistrict: boolean;
    isDistrictUser: boolean;
    isDistrictAdministrator: boolean;
    hasActiveDirectoryManagementAccess: boolean;
    hasInternalFormFeature: boolean;
    hasProcessTemplatesFeature: boolean;
    hasDistrictFormsFeature: boolean;
    hasSubscriptionsEnabled: boolean;
    hasDownloadResponsesFeature: boolean;
    hasApiAccess: boolean;
    hasIntegrationManagementAccess: boolean;
    hasEventTemplateFeature: boolean;
    hasSafePay: boolean;
    isAdministrator: boolean;
    isCanadianOrganization: boolean;
    isPermissionClick: boolean;
    countryCode: string;
    availableCountries: AvailableCountryListItem[];
    csrf: string;
    organizations: OrganizationListItem[];
    districtName: string;
    organizationName: string;
    organizationTitle: string;
    userName: string;
    userRoleName: string;
    email: string;
    profiles: ProfileListItem[];
    profilesWithAccessToCurrentPage: ProfileListItem[];
    editionName: string;
    error: Error;
    uris: {
        legacyBase: string;
        home: string;
        logo: string;
        dashboardApi: string;
        dashboard: string;
        myApprovals: string;
        processOverview: string;
        forms: string;
        templates: string;
        templatesApi: string;
        createOrganization: string;
        createOrganizationApi: string;
        manageUsers: string;
        editOrganization: string;
        editOrganizationApi: string;
        responseExporter: string
        responseExporterApi: string;
        brandingText: string;
        brandingTextApi: string;
        gstReport: string;
        api: string;
        depositReport: string;
        bankHook: string;
        subscriptionManagement: string;
        organizationSelection: string;
        organizationLogo: string;
        userIdentities: string;
        logout: string;
        integrationManagement: string;
        integrationManagementApi: string;
        integratedDataManagementApi: string;
        rosterManagement: string;
        rosterManagementApi: string;
        integratedDataManagement: string;
        getNotifications: string;
        acceptTermsApi: string;
        bulkUploadOrganizationsApi: string;
        bulkUploadUsersApi: string;
        bankHookApi: string;
        userManagementApi: string;
        confirmEmailUpdate: string;
        confirmEmailUpdateApi: string;
        pcUserManagementApi: string;
        multiAccountMergeUsersApi: string;

        featuresManagementApi: string;
        reportsApi: string;
        reportViewerApi: string;
        mergeOrganizationsApi: string;
        activeDirectoryManagement: string;
        activeDirectoryManagementApi: string;
        processOverviewApi: string;

        changeOrganizationTypeApi: string;
        userSearchApi: string;
        inviteDistrictUsersApi: string;
        formCreatorApi: string;
        templateActivityApi: string;
        templatePreviewApi: string;
        documentPreviewApi: string;
        myApprovalsApi: string;
        processTemplateActivityApi: string;
        processTemplateEditorApi: string;
        formManagerApi: string;
        documentApprovalApi: string;
        responseApprovalApi: string;

        processEditorApi: string;
    } & ReportsNavState["uris"];
    user: {
        isOrganizationAdministrator: boolean;
        isPrincipal: boolean;
        isEventOrganizer: boolean;
        profileId: string;
        acceptedTermsDate: string;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
    };
    organization: {
        hasSafePay: boolean;
        safePayActivated: boolean;
        hasMultiAccount: boolean;
        createdDate: string;
        countryCode: string;
        type: string;
        id: string;
    }
}

export const siteHeaderContext = createContext({} as ReactReduxContextValue<unknown, Action<unknown>>);


export const createSiteHeaderStore = (initialState: SiteHeaderState) => createStore({

    ...SetOrganizations,
    ...SetOrganization,
    ...SetFirstLoad,
    ...SetError,
    ...SetUnseenReportRequestCount,
    ...SetAcceptedTerms

}, initialState, "SiteHeaderState");
