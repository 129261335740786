import { RoutePaths } from '@src/Routes';
import { useSiteHeaderSelector } from '@src/customHooks/siteHeaderStore';
import { FullScreenLoading } from '@src/pages/LandingPages/FullScreenLoading';
import { isValidGUID } from '@src/utils/regex';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import jwt from '../../utils/jwt';
import { Dispatchers } from '../Dispatchers';
import { ErrorComponent } from '../molecules/Error/Error';
import { PrivateFooter } from '../molecules/PrivateFooter/PrivateFooter';
import { RunFirstLoad } from '../molecules/SiteHeader/Domain/Action/RunFirstLoad';
import { siteHeaderContext } from '../molecules/SiteHeader/Domain/Store';
import { AppPage } from './AppPage';

interface Props {
}


const dispatchers = {
    RunFirstLoad,
};

const ProtectedAppPageComponent: FC<Props & Dispatchers<typeof dispatchers>> = (props) => {
    const hasValidToken = jwt.hasValidToken();
    const userCountryCode = jwt.getCountry();
    const location = useLocation();

    const userIsLoaded = useSiteHeaderSelector((x) => !!x.user);
    const acceptedTermsDate = useSiteHeaderSelector((x) => x.user?.acceptedTermsDate);
    const error = useSiteHeaderSelector((x) => x.error);

    const hasAcceptedTerms = !!acceptedTermsDate;

    const { RunFirstLoad } = props;
    const { shardCode, organizationId } = useParams();

    const shardCodeToUse = shardCode || userCountryCode || "int";
    const orgIdToUse = organizationId && isValidGUID(organizationId) ? organizationId : undefined;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchFirstLoad() {
            if (hasValidToken) {
                setIsLoading(true);
                try {
                    await RunFirstLoad(shardCodeToUse, orgIdToUse); // run the First Load of site header, which contains the metadata required to run all nested "pages" loaded by this component.
                }
                finally {
                    setIsLoading(false);
                }
            }
            else if (!hasValidToken) {
                jwt.redirectToLogin();
            }
        }
        fetchFirstLoad();
    }, [RunFirstLoad, shardCodeToUse, orgIdToUse, hasValidToken]); // runs only once

    if (hasValidToken) {
        const currentPath = location.pathname.toLowerCase();
        const acceptTermsPath = RoutePaths.AcceptTerms.toLowerCase();

        if (error)
            return <>
                <div id="site-header">
                    <header></header>
                </div>
                <div id={"content"}>
                    <ErrorComponent error={error} />
                </div>
                <div id="private-footer">
                    <PrivateFooter />
                </div>
            </>;
        else if (!userIsLoaded || isLoading)
            return <FullScreenLoading />;
        else if (hasAcceptedTerms && currentPath === acceptTermsPath)
            return <Navigate to={RoutePaths.Landing} />;
        else if (!hasAcceptedTerms && currentPath === acceptTermsPath)
            return <Outlet />;
        else if (!hasAcceptedTerms && currentPath !== acceptTermsPath)
            return <Navigate to={RoutePaths.AcceptTerms} />;
        else
            return <AppPage>
                <Outlet />
            </AppPage>;

    } else {
        return <></>;
    }
};

export const ProtectedAppPage = connect(
    () => ({
    }),
    dispatchers, undefined, { context: siteHeaderContext }
)(ProtectedAppPageComponent);
