import { LoadingAnimation } from "@src/components/atoms/LoadingAnimation/LoadingAnimation";
import { DateFormats } from "@src/utils/DateFormats";
import { isEqual } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatchers } from "../../../Dispatchers";
import { getNotifications } from "../Domain/Action/GetNotifications";
import { BackgroundJobNotification, JobStatus } from "../Domain/BackgroundJobNotification";
import { SiteHeaderState, siteHeaderContext } from "../Domain/Store";


const dispatchers = {
    getNotifications,
};
interface Props {

}

interface State {
    showList: boolean;
    notifications: BackgroundJobNotification[];
    runAnimation: boolean;
    hasChanged: boolean;
}

class NotificationsComponent extends React.Component<Props & Dispatchers<typeof dispatchers>, State> {

    state = {} as State;

    private timer: number;
    public componentWillUnmount() {
        document.removeEventListener("visibilitychange", this.focusChange);
        this.clearTimer();
    }

    public componentDidMount() {
        document.addEventListener("visibilitychange", this.focusChange);
        this.focusChange();
    }

    public render() {

        return <>
            <div className="notifications-progress">
                <i
                    title="Notifications"
                    className={`fas fa-bell${this.state.hasChanged ? " faa-ring animated " : ""}`}
                    onClick={() => this.toggleList()}
                ></i>
                {this.renderProgressAnimation()}
            </div>
            {this.renderList()}
        </>;

    }


    private toggleList() {
        this.setState({ showList: !this.state.showList, runAnimation: undefined, hasChanged: this.state.hasChanged === true ? false : this.state.hasChanged }, () => {
            if (this.state.showList) {
                window.setTimeout(() => this.setState({ runAnimation: true }), 30);
                window.setTimeout(() => this.setState({ runAnimation: false }), 500);
            }
        });
    }
    private renderList() {
        if (this.state.showList) {

            return <nav>
                <div className="menu-background" onClick={() => this.toggleList()} />
                <div
                    className={`notifications ${this.state.runAnimation === undefined ? "animation-closed" : this.state.runAnimation === true ? "animation" : ""}`}
                >
                    <h3>Notifications</h3>
                    {this.renderNotifications()}
                </div>
            </nav>;
        }
    }

    private async refresh() {
        try {
            const notifications = await this.props.getNotifications();
            let hasChanged = this.state.hasChanged || false;
            if (!this.state.hasChanged && this.state.notifications) {
                hasChanged = !isEqual(notifications, this.state.notifications);
            }

            if (this.state.hasChanged !== hasChanged || this.state.showList) {
                this.setState({ notifications, hasChanged });
            }
        }
        finally {
            this.refreshTimer();
        }
    }

    private focusChange = () => {
        switch (document.visibilityState) {
            case "hidden":
                this.clearTimer();
                break;
            case "visible":
                this.refreshTimer();
                break;
        }
    }

    private clearTimer() {
        if (this.timer) {
            window.clearTimeout(this.timer);
        }
    }
    private refreshTimer() {
        this.clearTimer();
        this.timer = window.setTimeout(() => this.refresh(), 6000);
    }

    private renderNotifications() {
        if (this.state.notifications?.length > 0) {
            const myNotifications = this.state.notifications.filter((n) => n.ownedByUser);
            const otherNotifications = this.state.notifications.filter((n) => !n.ownedByUser);

            return <ul>
                {myNotifications.map((i) => this.renderNotification(i))}
                {otherNotifications.length > 0 && myNotifications.length > 0 ?
                    <li className="separator"></li> : null}
                {otherNotifications.map((i) => this.renderNotification(i))}
            </ul>;
        }
        else if (this.state.notifications === undefined) {

            return <LoadingAnimation isVisible={true} />;
        }
        else {

            return <p>You have no current notifications</p>;
        }

    }
    private renderNotification(item: BackgroundJobNotification) {
        let icon = "";
        let state = "";
        let time: Date;
        let wording = "has";
        switch (item.status) {
            case JobStatus.Running: {
                icon = "fas fa-cog fa-spin";
                state = "started";
                time = Date.utc(item.started);
                break;
            }
            case JobStatus.Succeded: {
                icon = "fas fa-check";
                state = "completed";
                time = Date.utc(item.completed);
                break;
            }
            case JobStatus.Failed: {
                icon = "fas fa-times";
                state = "failed";
                time = Date.utc(item.completed);
                break;
            }
            case JobStatus.Pending: {
                icon = "fas fa-clock";
                state = "pending";
                time = Date.utc(item.started);
                wording = "is";
                break;
            }
            case JobStatus.PartiallySucceeded: {
                icon = "fas fa-check";
                state = "partially completed";
                time = Date.utc(item.completed);
                break;
            }
        }
        const formName = item.document.name ? `for '${item.document.name}'` : "";
        const timeText = time.isValid() ? "at " + time.local().format(DateFormats.longWithTime) : "";
        let tooltipText = `Job requested ${formName}\r\n${timeText} by ${item.profile.name}.`;
        if (item.requestedBySystemUser) {
            tooltipText += "\r\n * Automatically started by system due to an event.";
        }
        const documentName = item.document.name && item.document.name.length > 20 ? item.document.name.substr(0, 20).trim() + "..." : item.document.name;

        return <li key={item.id} className={state.replace(" ", "-")} title={tooltipText}>
            <span className="icon fa-2x"><i className={icon} /></span>
            <span className="title">{item.description}</span>{documentName ? <> on <span className="title">{documentName}</span> form</> : null} {wording} <span className="state">{state}</span>.
            {time.isValid() ? <span className="time">{new Date().diff(time) < 1000 * 60 * 60 * 24 ? time.local().fromNow() : timeText}</span> : null}
        </li>;
    }

    private renderProgressAnimation() {

        return this.state.notifications?.findIndex((i) => i.status === JobStatus.Running) > -1 ? <span className="slider">
            <span className="line"></span>
            <span className="subline inc"></span>
            <span className="subline dec"></span>
        </span> : null;
    }

}

export const Notifications = connect(
    (_state: SiteHeaderState) => ({

    }),
    dispatchers,
    undefined,
    { context: siteHeaderContext }
)(NotificationsComponent);
