import { getAppInsights } from "@src/TelemetryProvider";
import { cloneDeep } from "lodash";
import { SetFirstLoadAction, SetFirstLoadActionType } from "../Action/RunFirstLoad";
import { SiteHeaderState } from "../Store";


export default {

    [SetFirstLoadActionType]: (state: SiteHeaderState, action: SetFirstLoadAction) => {

        const newState: SiteHeaderState = {
            ...cloneDeep(state),
            ...action.state
        };
        const { user } = newState;

        const applicationInsights = getAppInsights();
        if (applicationInsights?.context && user?.id) {
            applicationInsights.setAuthenticatedUserContext(user.id, user.profileId, false);
        }

        return newState;

    },
};
