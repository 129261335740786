
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { Provider } from "react-redux";
import { StyleSheetManager } from 'styled-components';
import GainsightProvider from "./GainsightProvider";
import { Router } from "./Routes";
import TelemetryProvider from "./TelemetryProvider";
import { CssReset } from "./components/layout";
import { SiteHeaderState, createSiteHeaderStore, siteHeaderContext } from "./components/molecules/SiteHeader/Domain/Store";
import { FullScreenLoading } from "./pages/LandingPages/FullScreenLoading";
import { theme as defaultTheme } from "./themes";
import jwt from "./utils/jwt";
import { shouldForwardProp } from "./utils/shouldForwardProp";

const siteHeaderStore = createSiteHeaderStore({} as SiteHeaderState);

console.log(process.env.APPVERSION);

const App = () => {
  const [initialized, setInitialized] = useState(false);
  const [initialLoad, setLoading] = useState(true);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);

      const fetchInitialToken = async () => {
        try {
          const queryString = new URLSearchParams(window.location.search);
          const code = queryString.get("code");
          await jwt.getFirstToken(code);
        }
        finally {
          setLoading(false);
        }
      }
      fetchInitialToken();
    }
  }, [initialized]);

  return (

    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <CssReset />
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <TelemetryProvider
            connectionString={process.env.APPLICATIONINSIGHTS_CONNECTION_STRING}
            appVersion={process.env.APPVERSION}
          >
            <Provider store={siteHeaderStore} context={siteHeaderContext}>
              <GainsightProvider>
                {initialLoad ? <FullScreenLoading /> : <Router />}
              </GainsightProvider>
            </Provider>
          </TelemetryProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StyleSheetManager>

  );
};

export { App };
